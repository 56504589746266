// ----------------------------------------------
// MIXINS

@mixin desktop {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin absolute100 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin before_after {
  content: "";
  display: block;
}

@mixin square($size) {
  height: $size;
  width: $size;
}

@mixin round($size) {
  border-radius: 50%;
  @include square($size);
}

@mixin vertical {
  display: flex;
  align-items: center;
  flex-direction: row;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6rem;
    height: 15rem;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-resizer {
    display: none;
  }

  &::-webkit-scrollbar-button:start {
    display: none;
  }

  &::-webkit-scrollbar-button:end {
    display: none;
  }

  &::-webkit-scrollbar-thumb:vertical {
    min-height: 20rem;
    background: #E2001A;
    border-radius: 0.1rem;
  }

  &::-webkit-scrollbar-track {
    background: #F6F6F6;
    border-radius: 0.1rem;
  }
}

.body {
  color: #fff;
  line-height: 1.2;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-size: 18rem;
}

* {
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

a,
button,
:focus,
:hover,
:active {
  outline: none;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  // min-height: 100vh;

  // display: flex;
  // flex-direction: column;

  &.modal-open {
    overflow: hidden;
    height: 100vh;

    .main {
      display: none;
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

acronym {
  text-decoration: none;
}

li {
  list-style: none;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;

  font-size: calc(100vw / 360);

  @include desktop {
    font-size: calc(100vw / 1280);
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: 'Geometria', Arial, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18rem;
  line-height: 1.4;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  border: 1rem solid transparent;
  color: inherit;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

picture {
  display: block;
}

.container {
  width: 100%;
  max-width: 1060rem;
  margin: 0 auto;
}
