@import "variables";
@import "layout";

.reviews-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40rem;

  @include mobile {
    grid-template-columns: 1fr;
    gap: 18rem;
  }
}

.review {
  border-radius: 30rem;
  background-color: $white-color;
  padding: 20rem 20rem 40rem;
  border: 1rem solid $main-color;
  color: $black-color;

  @include mobile {
    padding: 12rem 12rem 18rem;
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__img {
    width: 66rem;
    height: 66rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $accent-color;
    font-size: 45rem;
    font-weight: 500;
    color: $white-color;
    padding-bottom: 2rem;
    margin-right: 15rem;

    @include mobile {
      width: 42rem;
      height: 42rem;
      flex-shrink: 0;
      margin-right: 12rem;
      font-size: 26rem;
    }
  }

  &__title {
    font-size: 20rem;
    font-weight: 500;
    font-style: italic;
    color: $accent-color;

    @include mobile {
      font-size: 18rem;
    }
  }

  &__text {
    margin-top: 20rem;
    font-size: 18rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 6;

    p:not(:last-child) {
      margin-bottom: 20rem;
    }

    @include mobile {
      font-size: 16rem;
      margin-top: 12rem;
    }
  }

  &__btn {
    font-size: 16rem;
    color: $gray;
    margin-top: 20rem;
    text-decoration: underline;
    cursor: pointer;
    transition: color ease-in .24s;
    font-family: inherit;

    @include mobile {
      font-size: 14rem;
      margin-top: 16rem;
    }

    &:hover {
      color: #696666;
    }
  }
}

.more-btn {
  background-color: $accent-color;
  color: $white-color;
  padding: 28rem 80rem;
  border-radius: 15rem;
  font-size: 22rem;
  font-weight: 500;
  margin: 40rem auto 0;
  display: block;
  font-family: inherit;

  @include mobile {
    padding: 20rem;
    font-size: 16rem;
    width: 100%;
    border-radius: 10rem;
  }
}

.modal-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000071;
  z-index: 10;
  display: none;
  overflow: auto;
  cursor: pointer;

  @include mobile {
    padding: 24rem 12rem;
    height: 100%;
    min-height: calc(100vh + 48rem);
    padding-bottom: 100rem;
  }

  &.active {
    display: flex;
    justify-content: center;
  }
}

.modal {
  width: 690rem;
  position: relative;
  z-index: 20;
  padding: 100rem 0;
  display: flex;
  height: fit-content;
  margin: auto 0;

  @include mobile {
    width: 100%;
  }

  &__close-btn {
    width: 24rem;
    height: 24rem;
    position: absolute;
    top: 76rem;
    right: -34rem;
    cursor: pointer;

    @include mobile {
      right: 0;
      top: 58rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .review {
    display: none;
    cursor: default;
    height: fit-content;

    &.active {
      display: block;
    }
  }

  // .review__btn {
  //   display: none;
  // }
  .review__text {
    -webkit-line-clamp: unset;
  }

}