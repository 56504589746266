@import "variables";
@import "layout";

.about {
  color: $black-color;

  &-wrapper {
    background-color: $light-gray;
  }

  &__top {
    padding: 80rem 0;
    position: relative;
    padding-right: 500rem;

    @include mobile {
      display: flex;
      flex-direction: column;
      padding: 24rem 12rem;
    }
  }

  &__title {
    color: #a7a7a7;
    font-size: 22rem;

    @include mobile {
     font-size: 18rem;
     margin-top: 20rem; 
    }
  }

  &__name {
    font-size: 52rem;

    @include mobile {
      font-size: 26rem;
      margin-top: 12rem; 
    }
  }

  &__descr {
    font-size: 22rem;
    margin-top: 30rem;

    @include mobile {
     font-size: 16rem; 
     margin-top: 24rem;
    }

    p {
      &:not(:last-child) {
        @include mobile {
          margin-bottom: 18rem;
        }
      }
    }
  }
}

.experience {
  padding: 40rem 0 120rem;

  @include mobile {
    padding: 20rem 12rem 48rem;     
    font-size: 20rem;  
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 22rem;
    font-weight: 500;
    color: $accent-color;
  }

  &__text {
    font-size: 22rem;
    margin-top: 10rem;
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 40rem;
    }
  }
}

.author {
  position: absolute;
  top: 40rem;
  right: 0;
  width: 481rem;
  height: 526rem;

  @include mobile {
    position: static;
    order: -1;
    width: 227rem;
    height: 227rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
