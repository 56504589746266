@use "sass:math";

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-Bold'), local('Geometria-Bold'),
    url('fonts/Geometria-Bold.ttf') format('truetype'),
    url('fonts/Geometria-Bold.woff') format('woff'),
    url('fonts/Geometria-Bold.eot') format('eot');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Geometria';
  src: local('Geometria-Medium'), local('Geometria-Medium'),
    url('fonts/Geometria-Medium.ttf') format('truetype'),
    url('fonts/Geometria-Medium.woff') format('woff'),
    url('fonts/Geometria-Medium.eot') format('eot');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Geometria';
  src: local('Geometria'), local('Geometria'),
    url('fonts/Geometria.ttf') format('truetype'),
    url('fonts/Geometria.woff') format('woff'),
    url('fonts/Geometria.eot') format('eot');
  font-weight: 400;
  font-style: normal;
}

@import "_scss/layout";
@import "_scss/index";
@import "_scss/about-page";
@import "_scss/reviews";