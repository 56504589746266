@import "variables";

.body {
  &.js-no-scroll {
    overflow: hidden;
    height: 100vh;
  }
}

.header {

  @include mobile {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -1rem;
      left: 0;
      width: 100%;
      height: 1rem;
      background-color: #6EB62C;
      z-index: 2;
    }
  }

  &__logo {
    width: 62rem;
    height: 64rem;

    @include mobile {
      width: 46rem;
      height: 48rem;
    }

    &.--orange {
      color: $accent-color;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20rem 0;

    @include mobile {
      padding: 12rem;
    }
  }

  &__links {
    display: flex;
    color: $black-color;

    @include mobile {
      display: none;
    }
  }

}

.link {
  &:not(:last-child) {
    margin-right: 75rem;
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;

    img {
      width: 16rem;
      height: 16rem;
      margin-right: 5rem;
    }
  }
}

.menu-wrapper {
  padding: 22rem 0;
  background-color: $main-color;
  width: 100%;

  @include mobile {
    display: none;
  }
}

.menu {
  width: 100%;
  max-width: 1060rem;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @include mobile {}

  &__item {
    color: $black-color;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    transition: color ease-in .24s;
    padding: 0 3.5rem 24rem;
    position: relative;
    font-size: 20rem;

    @include mobile {
      margin: 0;
      font-size: 18rem;
      font-weight: 500;
      line-height: 1.77;
      padding-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      transform: translateX(-3.5rem);
      height: 10rem;
      border-radius: 10rem;
      background-color: $white-color;
      left: 0;
      bottom: 0;
      width: 0;
      transition: all ease-in .24s;

      @include mobile {
        transform: none;
      }
    }

    &:hover {
      color: $white-color;

      @include mobile {
        padding-bottom: 10rem;
      }

      &::before {
        width: calc(100% + 7rem);

        @include mobile {
          width: 100%;
        }
      }
    }

    &.active {
      color: $white-color;

      @include mobile {
        padding-bottom: 10rem;
      }

      &::before {
        @include mobile {
          width: 100%;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 81rem;

      @include mobile {
        margin-right: 0;
        margin-bottom: 20rem;
      }
    }
  }
}

.slide {
  padding: 118rem 0;

  @include mobile {
    padding: 0;
  }
}

.slide {
  height: 563rem;
  position: relative;

  @include mobile {
    height: auto;
  }

  &__wrap {
    max-width: 700rem;
    position: relative;
    z-index: 3;

    @include mobile {
      padding: 24rem 12rem 18rem;
    }
  }

  &__title {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #F38A32;
    font-size: 60rem;
    text-shadow: #6EB62C 0 0 4rem;

    @include mobile {
      font-size: 26rem;
      color: #1B1B1B;
      text-shadow: none;
    }
  }

  &__subtitle {
    font-size: 46rem;
    color: $accent-color;
    font-weight: 500;
    font-style: italic;
    text-align: right;
    margin: 20rem 113rem 0 0;

    @include mobile {
      font-size: 20rem;
      margin: 12rem 52rem 0 0;
    }
  }

  &__img {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @include mobile {
      position: relative;
      height: 280rem;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 10.53%, #FFF 60.33%);
      z-index: 2;

      @include mobile {
        background: linear-gradient(162deg, rgba(255, 255, 255, 0.00) 10.3%, #FFF 93.7%);
      }
    }

    img {
      width: 100%;
      height: 100%;
      position: relative;

      @include mobile {
        object-fit: cover;
      }
    }
  }
}

.slick-dots {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 96rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  @include mobile {
    bottom: 48rem;
  }

  li {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    background-color: #A7A7A7;
    color: transparent;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 15rem;
    }

    &.slick-active {
      background-color: $main-color;
    }
  }
}

.banner-wrapper {
  position: relative;
}

.cards-wrapper {
  background-color: $light-gray;
}

.cards-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40rem;

  @include mobile {
    grid-template-columns: 1fr;
    padding: 0 12rem;
    margin-top: 30rem;
    gap: 18rem;
  }
}

.card {
  padding: 20rem;
  border-radius: 30rem;
  border: 1rem solid $main-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $black-color;
  text-align: center;
  transform: translateY(-56rem);
  background-color: $white-color;
  min-height: 280rem;

  @include mobile {
    padding: 12rem 12rem 18rem;
    min-height: 276rem;
  }

  &__title {
    font-size: 22rem;
    font-weight: 500;
    margin-top: 20rem;

    @include mobile {
      font-size: 20rem;
      margin-top: 12rem;
    }
  }

  &__subtitle {
    margin-top: 15rem;
    font-size: 18rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 4;

    @include mobile {
      font-size: 16rem;
      margin-top: 12rem;
      -webkit-line-clamp: 3;
      margin-bottom: auto;
    }
  }

  &__img {
    width: 82rem;
    height: 82rem;

    @include mobile {
      width: 60rem;
      height: 60rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .review__btn:before {
    content: "Показать полностью";
  }

  &.expanded {
    .card__subtitle {
      display: block;
    }

    .review__btn:before {
      content: "Свернуть";
    }
  }
}

.description {
  padding: 40rem 0 120rem;
  color: $black-color;
  font-size: 22rem;

  @include mobile {
    font-size: 20rem;
    padding: 24rem 12rem 48rem;
  }
}

.page-top {
  padding: 80rem 0;
  color: $black-color;
  position: relative;

  @include mobile {
    padding: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 10.53%, #FFF 60.33%);
    z-index: 2;

    @include mobile {
      content: none;
    }
  }

  &__title {
    font-size: 52rem;

    @include mobile {
      font-size: 26rem;
    }
  }

  &__subtitle {
    font-size: 22rem;
    margin-top: 20rem;
    max-width: 380rem;

    @include mobile {
      font-size: 18rem;
      margin-top: 12rem;
    }
  }

  &__wrap {
    position: relative;
    z-index: 3;

    @include mobile {
      margin: 24rem 12rem 18rem;

    }
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 60%;


    @include mobile {
      position: relative;
      height: 107rem;
      width: 100%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(214deg, rgba(255, 255, 255, 0.00) -9.54%, #FFF 100%);
        z-index: 2;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include mobile {
      }
    }
  }
}

.page-bottom {
  color: $black-color;
  background-color: $light-gray;
  padding: 40rem 0 120rem;

  @include mobile {
    padding: 24rem 12rem 48rem;
  }

  &__title {
    font-size: 27rem;
    font-weight: 500;

    @include mobile {
      font-size: 20rem;
    }
  }
}

@include desktop {
  .mobile {
    display: none;
  }
}

@include mobile {
  .desktop {
    display: none;
  }
}

.mob-btn {
  &__burger {
    width: 34rem;
    display: none;

    &.active {
      display: block;
    }
  }

  &__close-btn {
    width: 18rem;
    display: none;

    &.active {
      display: block;
    }
  }

  img {
    width: 100%;
  }
}

.mob-menu {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  background-color: #6EB62C;
  z-index: 10;
  padding: 24rem 12rem 48rem;
  display: none;

  &.active {
    display: block;
  }

  .header__links {
    display: flex;
    flex-direction: column;
    margin-top: 44rem;

    span {
      display: none;
    }

    a {
      display: flex;
      align-items: center;
      margin-bottom: 12rem;
    }
  }

  .menu {
    flex-direction: column;
    align-items: start;
  }
}